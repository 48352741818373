const state = () => ({
  preferences: {
    general: {
      turnHeightOnCollapsed: 400
    }
  }
})

const getters = {
  turnHeightOnCollapsed(state) {
    return state.preferences.general.turnHeightOnCollapsed
  }
}
const actions = {
  setTurnHeightOnCollapsed({ commit }, payload) {
    commit('setTurnHeightOnCollapsed', payload)
  }
}

const mutations = {
  setTurnHeightOnCollapsed(state, payload) {
    state.preferences.general.turnHeightOnCollapsed = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
