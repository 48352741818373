import Empty from '@/app/shared/views/empty'
import i18n from '@/i18n'

const adminRoutes = [
  {
    path: 'projects',
    component: Empty,
    children: [
      {
        path: '',
        name: 'project-list',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.project')} ${i18n.t('dashboard.term.list')}`,
              to: { name: 'project-list' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/project/project-list')
      },
      {
        path: 'create',
        name: 'project-create',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.project')} ${i18n.t('dashboard.term.list')}`,
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.createProject')}`,
              to: { name: 'project-create' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/project/project-create')
      },
      {
        path: ':id/modify',
        name: 'project-modify',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: i18n.t('dashboard.term.projects'),
              to: { name: 'project-list' }
            },
            {
              text: i18n.t('dashboard.term.modifyProject'),
              to: { name: 'project-modify' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/project/project-create')
      },
      {
        path: ':id',
        name: 'project-detail',
        component: () => import('@/app/dashboard/views/admin/project/project-detail'),
        redirect: { name: 'project-detail-dataset-label' },
        children: [
          // {
          //   path: 'overview',
          //   name: 'project-detail-overview',
          //   meta: { requiresAuth: true, requiresWorkspace: true },
          //   component: () => import('@/app/dashboard/views/admin/project/project-detail-overview')
          // },
          {
            path: 'dataset',
            name: 'project-detail-dataset',
            components: {
              default: () => import('@/app/dashboard/views/admin/project/project-detail-dataset'),
              nav: () => import('@/app/dashboard/views/admin/project/partials/navigation-dataset')
            },
            redirect: { name: 'project-detail-dataset-label' },
            children: [
              {
                path: 'label',
                name: 'project-detail-dataset-label',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dataset'),
                      to: { name: 'project-detail-dataset' }
                    },
                    {
                      text: i18n.t('dashboard.term.label'),
                      to: { name: 'project-detail-dataset-label' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-dataset-label')
              },
              {
                path: 'assignment',
                name: 'project-detail-dataset-assignment',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dataset'),
                      to: { name: 'project-detail-dataset' }
                    },
                    {
                      text: i18n.t('dashboard.term.assignment_work'),
                      to: { name: 'project-detail-dataset-assignment' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-dataset-assignment')
              }
            ]
          },
          {
            path: 'workshops',
            name: 'project-detail-workshop-list',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.workshop'),
                  to: { name: 'project-detail-dataset' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-workshop-list')
          },
          {
            path: 'workshops/:workshop_id',
            name: 'project-detail-workshop-detail',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.workshopDetail'),
                  to: { name: 'project-detail-workshop-detail' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-workshop-detail')
          },
          {
            path: 'tags',
            name: 'project-detail-tags',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.tagsManagement'),
                  to: { name: 'project-detail-tags' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-tags')
          },
          {
            path: 'guides',
            name: 'project-detail-guide-list',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.guideline'),
                  to: { name: 'project-detail-guide-list' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-guide-list')
          },
          {
            path: 'guides/:guide_id',
            name: 'project-detail-guide-detail',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.modifyGuideline'),
                  to: { name: 'project-detail-guide-detail' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-guide-detail')
          },
          {
            path: 'quality-assurance',
            name: 'project-detail-quality-assurance',
            components: {
              default: () => import('@/app/dashboard/views/admin/project/project-detail-quality-assurance'),
              nav: () => import('@/app/dashboard/views/admin/project/partials/navigation-quality-assurance')
            },
            redirect: { name: 'project-detail-quality-assurance-reviewers' },
            children: [
              {
                path: 'reviewers',
                name: 'project-detail-quality-assurance-reviewers',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewerManagement'),
                      to: {
                        name: 'project-detail-quality-assurance-reviewers'
                      }
                    }
                  ]
                },
                component: () =>
                  import('@/app/dashboard/views/admin/project/project-detail-quality-assurance-reviewers')
              },
              {
                path: 'stages',
                name: 'project-detail-quality-assurance-stages',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.stageManagement'),
                      to: { name: 'project-detail-quality-assurance-stages' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-quality-assurance-stages')
              },
              {
                path: 'tags',
                name: 'project-detail-quality-assurance-tags',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewTagManagement'),
                      to: { name: 'project-detail-quality-assurance-tags' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-quality-assurance-tags')
              }
            ]
          },
          {
            path: 'statistics',
            name: 'project-detail-statistics',
            components: {
              default: () => import('@/app/dashboard/views/admin/project/project-detail-statistics'),
              nav: () => import('@/app/dashboard/views/admin/project/partials/navigation-statistics')
            },
            redirect: { name: 'project-detail-statistics-labeler' },
            children: [
              // {
              //   path: 'dataset',
              //   name: 'project-detail-statistics-dataset',
              //   meta: { requiresAuth: true, requiresWorkspace: true },
              //   component: () => import('@/app/dashboard/views/admin/project/project-detail-statistics-dataset')
              // },
              {
                path: 'labeler',
                name: 'project-detail-statistics-labeler',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.statistics'),
                      to: { name: 'project-detail-statistics' }
                    },
                    {
                      text: i18n.t('dashboard.term.labelerStatistics'),
                      to: { name: 'project-detail-statistics-labeler' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-statistics-labeler')
              },
              {
                path: 'reviewer',
                name: 'project-detail-statistics-reviewer',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.statistics'),
                      to: { name: 'project-detail-statistics' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewerStatistics'),
                      to: { name: 'project-detail-statistics-labeler' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/project/project-detail-statistics-reviewer')
              }
            ]
          },
          // {
          //   path: 'posts',
          //   name: 'project-detail-posts',
          //   meta: { requiresAuth: true, requiresWorkspace: true },
          //   component: () => import('@/app/dashboard/views/admin/project/project-detail-posts')
          // },
          {
            path: 'settings',
            name: 'project-detail-settings',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.preferences'),
                  to: { name: 'project-detail-settings' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/project/project-detail-settings')
          }
        ]
      }
    ]
  },
  {
    path: 'ml',
    component: Empty,
    redirect: { name: 'neural-net-list' },
    children: [
      {
        path: '',
        name: 'neural-net-layout',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.ml'),
              to: { name: 'neural-net-list' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-layout'),
        children: [
          {
            path: 'neural_net',
            name: 'neural-net-list',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.neuralNetworkDetail'),
                  to: { name: 'neural-net-list' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-list')
          },
          {
            path: 'neural_net/:neural_net_code',
            name: 'neural-net-detail',
            component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-detail'),
            redirect: { name: 'neural-net-detail-overview' },
            children: [
              {
                path: 'overview',
                name: 'neural-net-detail-overview',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkOverview'),
                      to: { name: 'neural-net-detail-overview' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-detail-overview')
              },
              {
                path: 'overview/train',
                name: 'neural-net-detail-train',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkTrain'),
                      to: { name: 'neural-net-detail-train' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-detail-train')
              },
              {
                path: 'model',
                name: 'neural-net-detail-model-list',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.models'),
                      to: { name: 'neural-net-detail-model-list' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-detail-model-list')
              },
              {
                path: 'model/:model_code',
                name: 'neural-net-detail-model-detail',
                meta: {
                  requiresAuth: true,
                  requiresWorkspace: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.models'),
                      to: { name: 'neural-net-detail-model-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.modelDetail'),
                      to: { name: 'neural-net-detail-model-detail' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/admin/neural_net/neural-net-detail-model-detail')
              }
            ]
          },
          {
            path: 'tasks',
            name: 'task-list',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.trainStatus'),
                  to: { name: 'task-list' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/task/task-list')
          },
          {
            path: 'tasks/:task_id',
            name: 'task-detail',
            meta: {
              requiresAuth: true,
              requiresWorkspace: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.neuralNetworkDetail'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.trainStatusDetail'),
                  to: { name: 'task-detail' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/task/task-detail')
          }
        ]
      }
    ]
  },
  {
    path: 'datasets',
    component: Empty,
    redirect: { name: 'dataset-list' },
    children: [
      {
        path: '',
        name: 'dataset-list',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/dataset/dataset-list')
      },
      {
        path: 'create',
        name: 'dataset-create',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.createDataset'),
              to: { name: 'dataset-create' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/dataset/dataset-create')
      },
      {
        path: ':id/modify',
        name: 'dataset-modify',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.modifyDataset'),
              to: { name: 'dataset-create' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/dataset/dataset-create')
      },
      {
        path: ':id',
        name: 'dataset-detail',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasetDetail'),
              to: { name: 'dataset-detail' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/dataset/dataset-detail')
      }
    ]
  },
  {
    path: 'integrations',
    name: 'integration-detail',
    component: () => import('@/app/dashboard/views/admin/integration/integration-detail'),
    meta: {
      requiresAuth: true,
      requiresWorkspace: true
    },
    redirect: { name: 'agent-list' },
    children: [
      {
        path: 'agent',
        name: 'agent-list',
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/admin/integration/agent-list')
      },
      {
        path: 'agent/:id',
        name: 'agent-detail',
        redirect: { name: 'agent-overview' },
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agentDetail'),
              to: { name: 'agent-detail' }
            }
          ]
        }
      },
      {
        path: 'storage',
        name: 'storage-list',
        component: () => import('@/app/dashboard/views/admin/integration/storage-list'),
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.storage'),
              to: { name: 'storage-list' }
            }
          ]
        }
      },
      {
        path: ':id/overview',
        name: 'agent-overview',
        component: () => import('@/app/dashboard/views/admin/integration/agent-overview'),
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.overview'),
              to: { name: 'agent-overview' }
            }
          ]
        }
      },
      {
        path: ':id/tasks',
        name: 'agent-tasks',
        component: () => import('@/app/dashboard/views/admin/integration/agent-tasks'),
        meta: {
          requiresAuth: true,
          requiresWorkspace: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.activateWork'),
              // text: i18n.t('dashboard.term.overview'),
              to: { name: 'agent-tasks' }
            }
          ]
        }
      }
    ]
  },
  {
    path: 'member',
    name: 'member-settings',
    component: () => import('@/app/dashboard/views/admin/member/member-settings'),
    redirect: { name: 'list' },
    children: [
      {
        path: '',
        name: 'member-layout',
        components: {
          default: () => import('@/app/dashboard/views/admin/member/member-layout')
        },
        children: [
          {
            path: 'list',
            name: 'member-list',
            meta: {
              requiresAuth: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.member'),
                  to: { name: 'member-list' }
                },
                {
                  text: i18n.t('dashboard.term.memberList'),
                  to: { name: 'member-list' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/member/member-list')
          }
        ]
      }
    ]
  },
  {
    path: 'settings',
    component: () => import('@/app/dashboard/views/admin/setting/setting'),
    redirect: { name: 'profile' },
    children: [
      {
        path: '',
        name: 'setting-layout',
        components: {
          default: () => import('@/app/dashboard/views/admin/setting/setting-layout'),
          nav: () => import('@/app/dashboard/views/admin/setting/partials/navigation-setting')
        },
        children: [
          {
            path: 'account',
            name: 'setting-account',
            meta: {
              requiresAuth: true,
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.settings'),
                  to: { name: 'settings' }
                },
                {
                  text: i18n.t('dashboard.term.accountSetting'),
                  to: { name: 'setting-user' }
                }
              ]
            },
            component: () => import('@/app/dashboard/views/admin/setting/setting-account')
          }
        ]
      }
    ]
  }
]

export default adminRoutes
