<template>
  <div v-if="schema" :style="`font-size: ${fontSize}px;`" class="json-schema-input">
    <div v-for="id in sort" :key="id">
      <template v-if="schema.properties[id]">
        <template v-if="schema.properties[id].enum.length > 0">
          <template v-if="schema.properties[id].widget === 'multi_choice'">
            <b-form-group
              :disabled="schema.isLocked"
              :label="
                (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                ' ' +
                (schema.required.includes(id) ? '*' : '')
              ">
              <b-form-checkbox-group
                v-if="Array.isArray(form[schema.properties[id].code])"
                v-model="form[schema.properties[id].code]">
                <b-form-checkbox
                  v-for="(d, i) in schema.properties[id].enum"
                  :key="d + i"
                  :value="d"
                  class="checkbox mr-3">
                  <span v-if="i18n.locale === 'ko'" :style="`font-size: ${fontSize}px;`">
                    {{ schema.properties[id].enumNames[i] }}
                  </span>
                  <span v-else :style="`font-size: ${fontSize}px;`">{{ schema.properties[id].enum[i] }}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
          <template v-else-if="schema.properties[id].widget === 'single_choice--radio'">
            <b-form-group :disabled="schema.isLocked">
              <template #label>
                <div @dblclick="selectNextOption(schema.properties[id].code, getOptions(schema.properties[id]))">
                  {{
                    (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                    ' ' +
                    (schema.required.includes(id) ? '*' : '')
                  }}
                </div>
              </template>
              <b-form-radio-group v-model="form[schema.properties[id].code]" class="single_choice--radio" stacked>
                <b-form-radio
                  v-for="(name, i) in schema.properties[id].enum"
                  :key="name"
                  :class="
                    schema.properties[id].enum[i] === form[schema.properties[id].code] ? 'radio-item--active' : null
                  "
                  :value="schema.properties[id].enum[i]"
                  class="w-100"
                  size="sm">
                  <div>
                    <span :style="{ color: schema.properties[id].enumValues[i] }">●</span>
                    <span v-if="i18n.locale === 'ko'">{{ schema.properties[id].enumNames[i] }}</span>
                    <span v-else>{{ schema.properties[id].enum[i] }}</span>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </template>
          <template v-else>
            <b-form-group :disabled="schema.isLocked">
              <template #label>
                <div @dblclick="selectNextOption(schema.properties[id].code, getOptions(schema.properties[id]))">
                  {{
                    (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                    ' ' +
                    (schema.required.includes(id) ? '*' : '')
                  }}
                  <!-- <template v-if="getOptions(schema.properties[id]).filter((d) => Boolean(d.value)).length === 1">
                    <span
                      style="
                        margin-left: 1rem;
                        background-color: rgb(47, 53, 74);
                        border-radius: 10px;
                        padding: 0 1rem;
                        font-size: 0.8rem;
                      ">
                      {{ getOptions(schema.properties[id]).filter((d) => Boolean(d.value))[0].text }}
                    </span>
                  </template> -->
                </div>
              </template>

              <!-- <b-form-select
                v-show="getOptions(schema.properties[id]).filter((d) => Boolean(d.value)).length !== 1"
                v-model="form[schema.properties[id].code]"
                :options="getOptions(schema.properties[id])"
                :style="`font-size: ${fontSize}px;`"
                size="sm"></b-form-select> -->
              <b-form-select
                v-model="form[schema.properties[id].code]"
                :options="getOptions(schema.properties[id])"
                :style="`font-size: ${fontSize}px;`"
                size="sm"></b-form-select>
            </b-form-group>
          </template>
        </template>
        <template v-else>
          <template v-if="schema.properties[id].widget === 'text'">
            <b-form-group
              :label="schema.properties[id].name + ' ' + (schema.required.includes(id) ? '*' : '')"
              :disabled="schema.isLocked">
              <textarea
                ref="textInput"
                v-model="form[schema.properties[id].code]"
                class="textarea"
                :style="`font-size: ${fontSize}px;`"
                max-rows="100"
                no-auto-shrink
                rows="3"></textarea>
            </b-form-group>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { cloneDeep, isEqual } from 'lodash'
import { useI18n } from '@/app/core/lib/util'
import i18n from '@/i18n'

let prevCodes = []

export default {
  name: 'json-schema-input',
  props: ['context'],
  setup(props, { root: { $store } }) {
    const schema = computed(() => props.context.slotProps.label.schema)
    const form = ref({ class: '' })
    const originForm = ref({})
    const sort = ref([])
    const { t } = useI18n()
    const textInput = ref(null)

    const findCode = (obj, keys, i) => {
      if (keys[i]) {
        if (keys.length - 1 === i) {
          const { code } = obj[keys[i]]
          return code
        }
        return findCode(obj[keys[i]], keys, i + 1)
      }
      return findCode(obj, keys, i + 1)
    }

    const initClassSelectionProps = (property) => {
      if (property.widget === 'multi_choice') {
        if (typeof form.value[property.code] === 'string') {
          if (property.default && !originForm.value[property.code]) {
            form.value[property.code] = property.default
          } else {
            form.value[property.code] = []
          }
        }
      } else if (property.widget === 'single_choice') {
        if (typeof form.value[property.code] === 'object') {
          if (property.default && !originForm.value[property.code]) {
            form.value[property.code] = property.default
          } else {
            form.value[property.code] = ''
          }
        }
      }

      if (!form.value[property.code]) {
        form.value[property.code] = property.default || ''
      }
    }

    const isVisible = (property) => {
      let result = false
      if (property) {
        if (property.condition) {
          const ref = property.condition.$ref
          const value = property.condition.const
          const code = findCode(schema.value, ref.split('/'), 0)
          result = form.value[code] === value
        } else {
          result = true
        }
      }
      return result
    }

    const setSort = () => {
      if (schema.value) {
        const { properties } = schema.value
        sort.value = []
        for (let i = 0; i < schema.value.sort.length; i++) {
          const id = schema.value.sort[i]
          const property = properties[id]
          if (isVisible(property)) {
            initClassSelectionProps(property)
            sort.value.push(id)
          }
        }
      }
    }

    watch(
      form,
      (v) => {
        // eslint-disable-next-line vue/no-mutating-props
        props.context.model = v
        setSort()
      },
      { deep: true, flush: 'post' }
    )

    watch(
      schema,
      (v) => {
        const getPropsString = () => {
          const result = new Set()
          const keys = []
          v.sort.map((key) => {
            const property = v.properties[key]
            if (property.condition) {
              const propertyId = property.condition.$ref.substr(property.condition.$ref.lastIndexOf('/') + 1)
              const value = property.condition.const
              if (props.context.model[v.properties[propertyId].code] === value) {
                result.add(property.code)
                const vv = props.context.model[v.properties[key].code]
                if (vv && typeof vv === 'object') {
                  for (let i = 0; i < vv.length; i++) {
                    if (v.properties[key].enum.includes(vv[i])) {
                      keys.push(key)
                      break
                    } else {
                      break
                    }
                  }
                } else if (typeof vv === 'string') {
                  if (v.properties[key].widget === 'text' || v.properties[key].enum.includes(vv)) {
                    keys.push(key)
                  }
                }
              } else {
                result.add(property.code)
                keys.push(key)
              }
            } else {
              result.add(property.code)
              keys.push(key)
            }
          })

          const codes = keys.map((key) => {
            return v.properties[key].code
          })

          if (!isEqual(prevCodes, codes)) {
            Object.keys(form.value).map((d) => {
              if (!codes.includes(d)) {
                form.value[d] = ''
              }
            })

            prevCodes = cloneDeep(codes)
          }
          return Array.from(result).join()
        }

        const stringfiedProps = getPropsString()
        const newData = { ...props.context.model }
        const propertyKeys = stringfiedProps.split(',')
        Object.keys(newData).forEach((key) => {
          if (!propertyKeys.includes(key)) {
            delete newData[key]
          }
        })
        form.value = newData
        originForm.value = { ...newData }
      },
      { flush: 'post' }
    )

    const getOptions = (property) => {
      const options = []
      options.push({
        text: `--${t('dashboard.term.select')} --`,
        value: ''
      })
      for (let i = 0; i < property.enum.length; i++) {
        const d = property.enum[i]
        let text
        if (i18n.locale === 'ko') {
          text = property.enumNames[i]
        } else {
          text = d
        }
        options.push({
          text,
          value: d
        })
      }
      return options
    }

    const fontSize = computed(() => $store.state.annotator.preferences.annotation.fontSize)

    const selectNextOption = (code, options) => {
      const model = form.value[code]
      if (options && options.length > 1) {
        let idx = options.findIndex((d) => d.value === model)

        if (idx === options.length - 1) {
          idx = 1
        } else {
          idx++
        }
        form.value[code] = options[idx].value
      }
    }

    const onTextInput = ({ target }) => {
      if (target) {
        target.style.height = 'auto'
        target.style.height = target.scrollHeight + 'px'
      }
    }

    watch(
      textInput,
      () => {
        applyNewTextInputHeight()
      },
      { once: true }
    )

    const applyNewTextInputHeight = () => {
      const v = textInput.value
      if (v?.length > 0) {
        v.forEach((d) => {
          d.setAttribute('style', 'height:' + d.scrollHeight + 'px;overflow-y:hidden;')
          d.addEventListener('input', onTextInput, false)
        })
      }
    }

    return {
      schema,
      form,
      sort,
      i18n,
      isVisible,
      getOptions,
      fontSize,
      selectNextOption,
      textInput
    }
  }
}
</script>

<style lang="scss" scoped>
input,
textarea,
select {
  color: #000 !important;
}

.checkbox {
  display: inline-block;
}

.radio-item--active {
  background: var(--border-color);
}

.textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--font-color);
  max-height: 800px;
  overflow-x: hidden;
  overflow-y: auto !important;
}
</style>

<style lang="scss">
.json-schema-input {
  .custom-control-input {
    width: 100%;
  }
}
</style>
