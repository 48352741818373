<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <div>
      <input class="form-control" type="file" @change="onFileInput" />
    </div>
    <div v-if="attachment" class="attachment">
      <a :download="attachment.split('/')[attachment.split('/').length - 1]" :href="attachment">
        {{ attachment.split('/')[attachment.split('/').length - 1] }}
      </a>
      <a
        :download="attachment.split('/')[attachment.split('/').length - 1]"
        :href="attachment"
        class="btn btn-secondary btn-sm ml-3">
        <i class="fe fe-download"></i>
        {{ $t('dashboard.term.download') }}
      </a>
      <button class="btn btn-danger btn-sm ml-2" @click="onDelete()">
        <i class="fe fe-trash"></i>
        삭제
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'custom-file-input',
  props: ['context'],
  setup(props) {
    const model = ref(null)
    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    const onFileInput = (e) => {
      if (e && e.target) {
        model.value = e.target.files[0]
      }
    }

    const attachment = computed(() => props.context.slotProps.label.formFile)

    const onDelete = () => {
      if (attachment.value) {
        model.value = null
      }
    }

    return {
      model,
      attachment,
      onDelete,
      onFileInput
    }
  }
}
</script>

<style lang="scss">
.attachment {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
}
</style>
