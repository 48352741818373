// PREFERENCE
export const OPTION = {
  ENABLE: true,
  DISABLE: false
}

export const PREFERENCE_SECTION = {
  POINTS: 'points',
  SELECTION: 'selection',
  CUBOID: 'cuboid',
  SEGMENTATION: 'segmentation',
  PROJECTION: 'projection',
  ROI: 'roi',
  ROI_BOX: 'roiBox'
}
export const PREFERENCE_KEY = {
  POINTS: {
    SIZE: 'size',
    COLOR: 'color'
  },
  SELECTION: { TRACKING_MODE: 'trackingMode' },
  CUBOID: { FILL_POINTS_COLOR: 'fillPointsColor' },
  SEGMENTATION: { BRUSH_SIZE: 'brushSize', MODE: 'mode' },
  PROJECTION: {
    VISIBLE: 'visible',
    VIEW_TYPE: 'viewType',
    IMAGE_BRIGHTNESS: 'imageBrightness'
  },
  ROI: { VISIBLE: 'visible', RADIUS: 'radius', AOV: 'aov' },
  ROI_BOX: { VISIBLE: 'visible' }
}
export const TOOL_NAME = {
  SEGMENTATION: '3d_segmentation',
  CUBOID: '3d_bounding_box',
  RELATION: 'relation',
  OBJECT_MODE: 'object_mode',
  ROI: 'roi'
}
// ERROR
export const ERROR = {
  SCREEN_RANGE: 'screen_range'
}
// VIEW
export const AXES_NAME = {
  X: 'x',
  Y: 'y',
  Z: 'z'
}
export const AXES_OF_3D = ['x', 'y', 'z']
export const VIEW_NAME = {
  MAIN: 'main',
  TOP: 'top',
  SIDE: 'side',
  FRONT: 'front'
}

export const PROJECTION_VIEW_TYPE = {
  SINGLE: 'single',
  MULTI: 'multi'
}
export const VIEW_TYPES = new Set(['main', 'top', 'side', 'front'])
export const SUBVIEW_TYPES = new Set(['top', 'side', 'front'])
// SUBVIEW
export const SUB_D = {
  [VIEW_NAME.TOP]: {
    w: AXES_NAME.Y,
    h: AXES_NAME.X,
    d: AXES_NAME.Z
  },
  [VIEW_NAME.SIDE]: {
    w: AXES_NAME.X,
    h: AXES_NAME.Z,
    d: AXES_NAME.Y
  },
  [VIEW_NAME.FRONT]: {
    w: AXES_NAME.Y,
    h: AXES_NAME.Z,
    d: AXES_NAME.X
  }
}

//= ================ CUBOID===================
export const CUBOID = {
  MODE: {
    CREATE: 'create',
    EDIT: 'edit'
  }
}

export const CUBOID_VERTICES = {
  BLT: {
    code: 'BLT',
    name: 'back-left-top',
    direction: {
      x: -1,
      y: 1,
      z: 1
    }
  },
  BRT: {
    code: 'BRT',
    name: 'back-right-top',
    direction: {
      x: 1,
      y: 1,
      z: 1
    }
  },
  BLB: {
    code: 'BLB',
    name: 'back-left-bottom',
    direction: {
      x: -1,
      y: 1,
      z: -1
    }
  },
  BRB: {
    code: 'BRB',
    name: 'back-right-bottom',
    direction: {
      x: 1,
      y: 1,
      z: -1
    }
  },
  FLT: {
    code: 'FLT',
    name: 'front-left-top',
    direction: {
      x: -1,
      y: -1,
      z: 1
    }
  },
  FRT: {
    code: 'FRT',
    name: 'front-right-top',
    direction: {
      x: 1,
      y: -1,
      z: 1
    }
  },
  FLB: {
    code: 'FLB',
    name: 'front-left-bottom',
    direction: {
      x: -1,
      y: -1,
      z: -1
    }
  },
  FRB: {
    code: 'FRB',
    name: 'front-right-bottom',
    direction: {
      x: 1,
      y: -1,
      z: -1
    }
  }
}

export const TRANSFORM_MODE = {
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale'
}
TRANSFORM_MODE.DEFAULT = TRANSFORM_MODE.TRANSLATE

//= ================ SEGEMENTATION===================
export const SEGMENTATION = {
  MODE: {
    SELECT: 'select',
    COLOR: 'color',
    ERASE: 'erase'
  },
  SELECT_TYPE: {
    CIRCLE: 'circle',
    RECT: 'rect',
    LASSO: 'lasso'
  },
  COLOR_MODE: {
    CLASS: 'class',
    OBJECT: 'object'
  }
}

export const VIEW_MODE_CODE = {
  ALL_POINTS: 'all-points',
  ONLY_SEGMENTED_POINTS: 'segmented-points',
  ONLY_UNSEGMENTED_POINTS: 'unsegmented-points'
}

export const VIEW_MODE_MESSAGE = {
  [VIEW_MODE_CODE.ALL_POINTS]: null,
  [VIEW_MODE_CODE.ONLY_SEGMENTED_POINTS]: 'Segmented points',
  [VIEW_MODE_CODE.ONLY_UNSEGMENTED_POINTS]: 'Unsegmented points'
}

export const VIEW_MODE_COLOR = {
  [VIEW_MODE_CODE.ALL_POINTS]: null,
  [VIEW_MODE_CODE.ONLY_SEGMENTED_POINTS]: '#fff',
  [VIEW_MODE_CODE.ONLY_UNSEGMENTED_POINTS]: '#fff'
}

// Direction scale factor index of position array on box mesh
export const DIRECTION_SCALE_INDEX = 75
