import { getCurrentInstance, reactive, toRefs, watch, set } from '@vue/composition-api'
import chroma from 'chroma-js'
import hsl from 'hsl-to-hex'
import { cloneDeep, isEqual } from 'lodash'
import { nanoid } from 'nanoid'
import Vue from 'vue'
import { useEventManager } from '@/app/annotator/core/lib/event-manager'
import { useAnnotation } from '@/app/core/lib/data'
import i18n from '@/i18n'

const __DEV__ = process.env.NODE_ENV === 'development'

let tempLogMessages = []
export const useLog = () => {
  const getBrightRandomHexColor = () => {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)
    const hex = {
      r: '0' + r.toString(16),
      g: '0' + g.toString(16),
      b: '0' + b.toString(16)
    }
    const color =
      '#' +
      hex.r.substring(hex.r.length - 2, hex.length) +
      hex.g.substring(hex.g.length - 2, hex.length) +
      hex.b.substring(hex.b.length - 2, hex.length)
    const luminance = chroma(color).luminance()
    if (luminance < 0.1) {
      return getBrightRandomHexColor()
    } else {
      return color
    }
  }

  let backgroundColor = 'transparent'
  const color = getBrightRandomHexColor()

  const _log = (type, className, ...args) => {
    if (isEqual(tempLogMessages, [className, ...args])) {
      return false
    }

    const message = [
      `%c${type[0]}%c${className}`,
      type[1],
      `color: ${color};
        background-color: ${backgroundColor};
        border: 1px solid ${color};
        padding: 1px 5px;
        width: 300px;
        display: inline-block;
        border-radius: 2px;
        font-size: 0.3rem;
        `
    ]
    if (args?.length > 0) {
      args.forEach((arg) => {
        message.push(arg)
      })
    }

    __DEV__ && console.log(...message)
    tempLogMessages = [className, ...args]
  }

  const sharedStyle = `
  color:#000;
  border: 1px solid #202124;
  font-weight: bold;
  padding: 0 5px;
  margin-right: 5px;
  font-size: 0.3rem;
  `

  return {
    log: (className, ...args) => {
      _log(['INFO ', `${sharedStyle}background-color:#17b2f1;`], className, ...args)
    },
    debug: (className, ...args) => {
      _log(['DEBUG', `${sharedStyle}background-color:#8acc44;`], className, ...args)
    },
    warn: (className, ...args) => {
      _log(['WARN ', `${sharedStyle}background-color:#ffc217;`], className, ...args)
    },
    error: (className, ...args) => {
      _log(['ERROR', `${sharedStyle}background-color:#da6d1c;`], className, ...args)
    },
    fatal: (className, ...args) => {
      _log(['FATAL', `${sharedStyle}background-color:#f91314;`], className, ...args)
    }
  }
}
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route
  })

  watch(
    () => vm.$route,
    (r) => {
      state.route = r
    }
  )

  return {
    ...toRefs(state),
    router: vm.$router
  }
}

export const useI18n = () => {
  const vm = getCurrentInstance().proxy

  return {
    i18n: vm.$i18n,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  }
}

export const useToast = () => {
  const vm = getCurrentInstance().proxy
  return {
    toast: vm.$toast
  }
}

export const useWorker = () => {
  const vm = getCurrentInstance().proxy

  return {
    worker: vm.$worker
  }
}

export const live = (eventType, elementQuerySelector, cb) => {
  const { pushEvent } = useEventManager()
  const eventHandler = (event) => {
    const qs = document.querySelectorAll(elementQuerySelector)
    if (qs) {
      let el = event.target
      let index = -1
      while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
        el = el.parentElement
      }

      if (index > -1) {
        cb.call(el, event)
      }
    }
  }
  pushEvent(document, eventType, eventHandler, true)
  document.addEventListener(eventType, eventHandler, true)
}

export const setKeyBindingMap = (keyBindingMap, codeStr, callback) => {
  const codes = codeStr.split('|')
  for (let i = 0; i < codes.length; i++) {
    const code = codes[i]
    keyBindingMap[code] = callback
  }
}

export const watchResize = (el) => {
  return (fn) => new ResizeObserver(fn).observe(el)
}

export const addComma = (num) => {
  if (num) {
    const regexp = /\B(?=(\d{3})+(?!\d))/g
    return num.toString().replace(regexp, ',')
  } else {
    return '0'
  }
}

export const getLabel = (variables, annotationId, toolParam, classificationParam) => {
  const annotatorData = variables.annotatorData
  let classification
  if (classificationParam) {
    classification = classificationParam
  } else {
    const { annotation } = useAnnotation(variables, annotationId)
    classification = annotation?.classification || { class: '' }
  }
  let tool
  if (toolParam) {
    tool = toolParam
  } else {
    const { annotation } = useAnnotation(variables, annotationId)
    tool = annotation.tool
  }
  const locale = i18n.locale
  const result = []
  const classificationClone = cloneDeep(classification)
  const schema = annotatorData.classification[tool]?.classification_schema
  if (!schema) return result
  const idList = schema.sort
  let keys = Object.keys(classificationClone)
  for (let i = 0; i < idList.length; i++) {
    const id = idList[i]
    const obj = schema.properties[id]
    if (keys.length === 0) {
      break
    }

    if (obj.condition && obj.condition.$ref && obj.condition.const) {
      const arr = obj.condition.$ref.split('/')
      const c = schema.properties[arr[arr.length - 1]].code
      if (classificationClone[c] !== obj.condition.const) {
        continue
      }
    }

    if (!keys.includes(obj.code)) {
      continue
    }

    if (['single_choice', 'single_choice--radio'].includes(obj.widget)) {
      const idx = obj.enum.indexOf(classificationClone[obj.code])
      if (idx > -1) {
        let name
        if (locale === 'ko') {
          name = obj.enumNames[idx]
        } else {
          name = obj.enum[idx]
        }
        const color = obj.enumValues[idx]
        const r = {
          text: name,
          color: color,
          widget: obj.widget,
          code: obj.code,
          display: {
            ko: obj.enumNames[idx],
            en: obj.enum[idx]
          }
        }
        if (obj.customFields && obj.customFields.length > 0) {
          r.customFields = obj.customFields[idx]
        }
        result.push(r)
        keys = keys.filter((d) => d !== obj.code)
      }
    } else if (obj.widget === 'multi_choice') {
      const items = classificationClone[obj.code]
      if (items) {
        for (let j = 0; j < items.length; j++) {
          const item = items[j]
          const idx = obj.enum.indexOf(item)
          if (idx > -1) {
            let name
            if (locale === 'ko') {
              name = obj.enumNames[idx]
            } else {
              name = obj.enum[idx]
            }
            const color = obj.enumValues[idx]
            const r = {
              text: name,
              color: color,
              widget: obj.widget,
              code: obj.code,
              display: {
                ko: obj.enumNames[idx],
                en: obj.enum[idx]
              }
            }
            if (obj.customFields && obj.customFields.length > 0) {
              r.customFields = obj.customFields[idx]
            }
            result.push(r)
          }
        }
        keys = keys.filter((d) => d !== obj.code)
      }
    } else {
      if (classificationClone[obj.code]) {
        const r = {
          text: classificationClone[obj.code],
          color: '',
          widget: obj.widget,
          code: obj.code,
          display: {
            ko: classificationClone[obj.code],
            en: classificationClone[obj.code]
          }
        }
        result.push(r)
        keys = keys.filter((d) => d !== obj.code)
      }
    }
  }

  if (result.length > 0) {
    result.splice(0, 0, {
      ...result[0],
      text: ''
    })
  }

  const representativeCodes = variables.annotatorData.classification[tool].representativeCodes
  if (representativeCodes && representativeCodes.length > 0) {
    for (let i = 0; i < result.length; i++) {
      result[i].isRepresentative = representativeCodes.includes(result[i].code)
    }
  }

  return result
}

export const getRepresentativeColor = (variables, tool, label = []) => {
  const classification = variables.annotatorData.classification[tool]
  let color = ''
  if (classification) {
    let representativeCodes = classification.representativeCodes
    if (!representativeCodes || representativeCodes.length === 0) {
      representativeCodes = ['class']
    }
    for (let i = 0; i < representativeCodes.length; i++) {
      const code = representativeCodes[i]
      if (label && label.length > 0) {
        for (let j = 0; j < label.length; j++) {
          const obj = label[j]
          if (code === obj.code) {
            color = obj.color
          }
        }
        if (color) {
          break
        }
      }
    }
  }
  if (!color) {
    const l = label.find((d) => d.code === 'class')
    if (l) {
      color = l.color
    }
  }
  return color
}

export const getFileTypeFromUrl = (url) => {
  const arr = url.split('.')
  const ext = arr[arr.length - 1].toUpperCase()
  if (['JPG', 'PNG', 'JPEG'].includes(ext)) {
    return 'image'
  } else {
    return ext
  }
}

export const getClass = (variables, annotation) => {
  if (!variables.annotatorData && !variables.assignment) {
    return {
      code: '',
      color: '',
      name: ''
    }
  }
  const classification = variables.annotatorData.classification
  if (classification && classification[annotation.tool]) {
    const properties = classification[annotation.tool].classification_schema.properties
    let enums = []
    let enumNames = []
    let enumValues = []
    for (const key in properties) {
      if (properties[key].code === 'class') {
        enums = properties[key].enum
        enumNames = properties[key].enumNames
        enumValues = properties[key].enumValues
      }
    }
    const idx = enums.indexOf(annotation.classification.class)
    if (idx === -1) {
      return {
        code: '',
        color: '',
        name: ''
      }
    }
    return {
      code: enums[idx],
      color: enumValues[idx],
      name: enumNames[idx]
    }
  } else {
    return {
      code: '',
      color: '',
      name: ''
    }
  }
}

export const initClassSelection = (variables) => {
  const jsonSchema = variables.annotatorData.classification
  const annotationTypes = Object.keys(jsonSchema)

  const state = {}
  annotationTypes.forEach((annotationType) => {
    state[annotationType] = { classification: { class: '' } }
  })
  return state
}

export const osKey = (windowChar, macChar) => {
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return macChar
  } else {
    return windowChar
  }
}

export const getMetaKeyPressed = (event) => {
  return navigator.userAgentData.platform.includes('mac') ? event.metaKey : event.ctrlKey
}

export const conversionHsl = (hex) => {
  if (!hex) {
    return hex
  }

  if (hex.toLowerCase().indexOf('hsl') === 0) {
    const arr = hex.split(',')
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) {
        arr[i] = arr[i].split('(')[1]
      }
      arr[i] = parseInt(arr[i])
    }
    hex = hsl(arr[0], arr[1], arr[2])
  }
  return hex
}

export const hexStrToHexNum = (hex) => {
  return parseInt(hex.replace(/^#/, ''), 16)
}

export const hexToNum = (hex) => {
  return chroma(hex).num()
}

export const getTextColor = (backgroundColor) => {
  if (backgroundColor) {
    if (chroma(backgroundColor).luminance() <= 0.5) {
      return '#ffffff'
    } else {
      return '#000000'
    }
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const useDynamicScript = (args) => {
  return new Promise((resolve, reject) => {
    if (args.url) {
      const element = document.createElement('script')
      document.head.appendChild(element)
      element.addEventListener('load', () => {
        resolve()
      })

      element.addEventListener('error', () => {
        reject(new Error(`Dynamic Script Error: ${args.url}`))
      })

      element.setAttribute('src', args.url)
      element.setAttribute('type', 'text/javascript')
      element.setAttribute('async', 'true')

      document.head.removeChild(element)
    }
  })
}

export const loadComponent = async (url, scope, module) => {
  await useDynamicScript({ url })
  return async () => {
    // eslint-disable-next-line no-undef
    await __webpack_init_sharing__('default')
    const container = window[scope]
    // eslint-disable-next-line no-undef
    await container.init(__webpack_share_scopes__.default)
    const factory = await window[scope].get(module)
    const Module = factory()
    return Module
  }
}

export const useRemoteScript = async (url, scope, module) => {
  const m = await loadComponent(url, scope, module)
  try {
    return await m()
  } catch (e) {
    console.error(e)
  }
}

export const fullscreen = (element) => {
  const rfs =
    element.requestFullscreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullscreen
  rfs.call(element)
}

export const exitFullscreen = () => {
  const efs =
    document.exitFullscreen ||
    document.webkitExitFullscreen ||
    document.mozCancelFullScreen ||
    document.msExitFullscreen
  efs.call(document)
}

export const isFullscreenMode = () => {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  )
}

export const createIntegerIdGenerator = function (start) {
  let id = start
  return function () {
    id += 1
    return id
  }
}

export const getLastInstanceId = (annotations) => {
  if (annotations.length === 0) {
    return 0
  }
  const instanceStringIdSet = new Set()
  const instanceIntegerIdSet = new Set()
  annotations.forEach((annotation) => {
    if (annotation.instanceId) {
      if (Number.isSafeInteger(annotation.instanceId)) {
        instanceIntegerIdSet.add(annotation.instanceId)
      } else {
        instanceStringIdSet.add(annotation.instanceId)
      }
    }
  })

  const integerArray = Array.from(instanceIntegerIdSet)
  let lastIntegerValue = 0
  if (integerArray.length > 0) {
    lastIntegerValue = integerArray.sort((a, b) => a - b).pop()
  }

  Array.from(instanceStringIdSet).forEach((instanceId) => {
    lastIntegerValue++
    annotations.forEach((annotation) => {
      if (annotation.instanceId && annotation.instanceId === instanceId) {
        annotation.instanceId = lastIntegerValue
      }
    })
  })

  return lastIntegerValue
}

export const handleErrorLog = (variables, messages, type) => {
  const id = nanoid(10)
  if (!Array.isArray(messages)) {
    messages = [messages]
  }

  messages.forEach((message) => {
    variables.errorLog.errorList.push({
      message,
      timestamp: new Date(),
      selectedErrorId: id
    })
    switch (type) {
      case 'error':
        Vue.$toast.error(message.replaceAll('\n', '<br/>'))
        break
      case 'warning':
        Vue.$toast.warning(message.replaceAll('\n', '<br/>'))
        break
      default:
        Vue.$toast.error(message.replaceAll('\n', '<br/>'))
        break
    }
  })
}

export const initAnnotationOptions = (annotation) => {
  if (annotation.isDrawCompleted === undefined) {
    annotation.isDrawCompleted = true
  }
  if (annotation.isVisible === undefined) {
    set(annotation, 'isVisible', true)
  }
  if (annotation.isLocked === undefined) {
    set(annotation, 'isLocked', false)
  }
}

export const initRelationOptions = (relation) => {
  if (relation.isVisible === undefined) {
    set(relation, 'isVisible', true)
  }
  if (relation.isLocked === undefined) {
    set(relation, 'isLocked', false)
  }
}
